<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-07-21 17:51:56
 * @LastEditors: hutian
 * @LastEditTime: 2021-07-22 02:08:51
-->
<template>
  <div>
    <a-card :loading="loading" :bordered="false" :body-style="{ padding: '0' }">
      <div class="salesCard">
        <a-tabs default-active-key="1" size="large" :tab-bar-style="{ marginBottom: '24px', paddingLeft: '16px' }">
          <div class="extra-wrapper" slot="tabBarExtraContent">
            <div class="extra-item">
              <a @click="onNow('Today')">今日</a>
              <a @click="onNow('Week')">本周</a>  
              <a @click="onNow('month')">本月</a>
              <a @click="onNow('year')">本年</a>
            </div>
            <a-range-picker v-model="chartTime" @change="onChange" :style="{ width: '256px' }" />
          </div>
          <a-tab-pane loading="true" tab="销售额排行" key="1">
            <a-row>
              <a-col :xl="16" :lg="12" :md="12" :sm="24" :xs="24">
                <bar :data="barData" title="销售额排行(单位：万元)" />
              </a-col>
              <a-col :xl="8" :lg="12" :md="12" :sm="24" :xs="24">
                <rank-list title="经销商" style="padding: 0 15px 15px 0; height: 500px" :list="rankList" />
              </a-col>
            </a-row>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-card>
  </div>
</template>

<script>
import moment from 'moment'
import {
  ChartCard,
  MiniArea,
  MiniBar,
  MiniProgress,
  RankList,
  Bar,
  Trend,
  NumberInfo,
  MiniSmoothArea,
} from '@/components'
//import { baseMixin } from '@/store/app-mixin'

const barData = []
const barData2 = []
for (let i = 0; i < 15; i += 1) {
  barData.push({
    x: `大区${i + 1}`,
    y: Math.floor(Math.random() * 10000) + 1500,
  })
  barData2.push({
    x: `${i + 1}月`,
    y: Math.floor(Math.random() * 1000) + 200,
  })
}

const rankList = []
for (let i = 0; i < 15; i++) {
  rankList.push({
    name: '大区 ' + (i + 1),
    total: (1234.56 - i * 100).toFixed(2),
  })
}

const searchUserData = []
for (let i = 0; i < 10; i++) {
  searchUserData.push({
    x: moment().add(i, 'days').format('YYYY-MM-DD'),
    y: Math.ceil(Math.random() * 10),
  })
}
const searchUserScale = [
  {
    dataKey: 'x',
    alias: '时间',
  },
  {
    dataKey: 'y',
    alias: '用户数',
    min: 0,
    max: 10,
  },
]

const searchTableColumns = [
  {
    dataIndex: 'index',
    title: '排名',
    width: 90,
  },
  {
    dataIndex: 'keyword',
    title: '经销商',
  },
  {
    dataIndex: 'count',
    title: '报备数',
  },
  {
    dataIndex: 'range',
    title: '成交数',
    align: 'right',
    // sorter: (a, b) => a.range - b.range,
    // scopedSlots: { customRender: 'range' }
  },
]
const searchData = []
for (let i = 0; i < 50; i += 1) {
  searchData.push({
    index: i + 1,
    keyword: `经销商-${i}`,
    count: Math.floor(Math.random() * 1000),
    range: Math.floor(Math.random() * 100),
    status: Math.floor((Math.random() * 10) % 2),
  })
}

const DataSet = require('@antv/data-set')

const sourceData = [
  { item: '空调', count: 32.2 },
  { item: '采暖', count: 21 },
  { item: '净水', count: 17 },
  { item: '空气净化', count: 13 },
  { item: '新风系统', count: 9 },
  { item: '家用电梯', count: 7.8 },
]

const pieScale = [
  {
    dataKey: 'percent',
    min: 0,
    formatter: '.0%',
  },
]

const dv = new DataSet.View().source(sourceData)
dv.transform({
  type: 'percent',
  field: 'count',
  dimension: 'item',
  as: 'percent',
})
const pieData = dv.rows

export default {
  name: 'Analysis',
  //mixins: [baseMixin],
  components: {
    ChartCard,
    MiniArea,
    MiniBar,
    MiniProgress,
    RankList,
    Bar,
    Trend,
    NumberInfo,
    MiniSmoothArea,
  },
  data() {
    return {
      loading: true,
      rankList,
      // 搜索用户数
      searchUserData,
      searchUserScale,
      searchTableColumns,
      searchData,
      barData,
      barData2,
      //
      pieScale,
      pieData,
      sourceData,
      pieStyle: {
        stroke: '#fff',
        lineWidth: 1,
      },
      today: moment(),
      week: [moment().startOf('week'), moment()],
      month: [moment().startOf('month'), moment()],
      year: [moment().startOf('year'), moment()],
      chartTime: [moment().startOf('year'), moment()]
    }
  },
  created() {
    setTimeout(() => {
      this.loading = !this.loading
    }, 1000)
    this.getCharts()
  },
  methods: {
    onNow(now) {
      if (now === 'Today') {
        this.chartTime = [this.today, this.today]
        this.getCharts()
      } else if (now === 'Week') {
        this.chartTime = [this.week[0], this.week[1]]
        this.getCharts()
      } else if (now === 'month') {
        this.chartTime = [this.month[0], this.month[1]]
        this.getCharts()
      } else {
        this.chartTime = [this.year[0], this.year[1]]
        this.getCharts()
      }
    },
    onChange(date, dateString) {
      if(date.length < 1){
        this.chartTime = [moment().startOf('year'), moment()]
      }
      this.getCharts()
    },
    getCharts() {
      // let yesr = new Date().getFullYear()
      // let month = new Date().getMonth() + 1 >= 10 ? new Date().getMonth() + 1 : '0' + (new Date().getMonth() + 1)
      // let day = new Date().getDate() >= 10 ? new Date().getDate() : '0' + new Date().getDate()
      // let date1 = yesr + '-01-01'
      // let date2 = yesr + '-' + month + '-' + day
      let params = {
        type: 1,
        typeDate: 99,
        date1:this.chartTime[0].format("YYYY-MM-DD"),
        date2:this.chartTime[1].format("YYYY-MM-DD")
      }
      this.axios.post(`/api/base/system/report/getReportOrderByType`, params).then((res) => {
        let list = res.body
        let barData = []
        let rankList = []
        for (let i = 0; i < list.length; i += 1) {
          barData.push({
            x: list[i][0],
            y: list[i][1],
          })
          rankList.push({
            name: list[i][0],
            total: list[i][1],
          })
        }
        this.barData = barData
        this.rankList = rankList
      })
    },
  },
}
</script>

<style lang="less" scoped>
.extra-wrapper {
  line-height: 55px;
  padding-right: 24px;

  .extra-item {
    display: inline-block;
    margin-right: 24px;

    a {
      margin-left: 24px;
    }
  }
}

.antd-pro-pages-dashboard-analysis-twoColLayout {
  position: relative;
  display: flex;
  display: block;
  flex-flow: row wrap;
}

.antd-pro-pages-dashboard-analysis-salesCard {
  height: calc(100% - 24px);
  /deep/ .ant-card-head {
    position: relative;
  }
}

.dashboard-analysis-iconGroup {
  i {
    margin-left: 16px;
    color: rgba(0, 0, 0, 0.45);
    cursor: pointer;
    transition: color 0.32s;
    color: black;
  }
}
.analysis-salesTypeRadio {
  position: absolute;
  right: 54px;
  bottom: 12px;
}
</style>
